import React, { FC } from 'react';
import { withPrefix } from 'gatsby';
import { Router } from '@reach/router';
import { Route } from 'components';

import BaseUpgrade from './index';

const Upgrade: FC = () => {
  const routes = Array.from({ length: 100 }).map((_, i) => ('0' + i).slice(-2));

  return (
    <Router basepath={withPrefix('/start/upgrade')}>
      {routes.map(route => Route(`/${route}`, BaseUpgrade))}
    </Router>
  );
};

export default Upgrade;
